<template>
  <div style="height: 100%;">
    <div style=" height: 100%;">
      <div class="row h-100 align-content-center" style="margin-top: 36px;">
        <div class="col-m-12 h-100" style="background: white; position: relative;">
          <div class="row g-0">
            <div class="col-12">
              <!-- <router-link
                            :to="{
                                name:  'DashboardHome'
                            }"
                            class="btn">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </router-link> -->

            </div>
            <div class="col-12">
              <div class="row g-0">
                <div class="col-6 col-md-12" style="text-align: center;">
                  <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen" class="center-app-logo ritz-logo">
                </div>
                <div class="col-6 col-md-12" style="text-align: center;">
                  <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen" class="center-app-logo">
                </div>
              </div>
            </div>
            <div class="col-12 item-section">
              <span v-for="assetType in assetTypes" class="py-1 ">
                <router-link v-if="assetType.LinkName === 'available-tenancies'" :key="assetType.Guid" :to="{
                  name: componentName,
                  params: {
                    assetType: assetType.LinkName
                  }
                }" class="btn-theme-menu mb-1 menu-title" @click="this.closeOffCanvas">
                  {{ assetType.ObjectName }}
                </router-link>

                <router-link v-if="assetType.LinkName === 'main-beach-superyacht-marina'" :key="assetType.Guid" :to="{
                  name: 'MainBeachSuperyachtMarinaAssetType',
                  params: {
                    assetType: assetType.LinkName,
                  }
                }" class="btn-theme-menu mb-1 menu-title text-center" style="line-height: 1.2">
                  {{ assetType.ObjectName }}
                </router-link>

                <!-- <router-link v-if="assetType.LinkName === 'retail-piazza'" :key="assetType.Guid" :to="{
                  name: 'RetailPiazzaAssetType',
                  params: {
                    assetType: assetType.LinkName
                  }
                }" class="btn-theme-menu mb-1">
                  {{ assetType.ObjectName }}
                </router-link> -->
                <a href="#" target="_blank" v-if="assetType.LinkName === 'vr-tour'" :key="assetType.Guid"
                  class="btn-theme-menu mb-1 menu-title">
                  VR Tour
                </a>
                <router-link v-if="assetType.LinkName === 'ritz-carlton'" :key="assetType.Guid" :to="{
                  name: 'RitzCarltonAssetType',
                  params: {
                    assetType: assetType.LinkName
                  }
                }" class="btn-theme-menu mb-1 menu-title" @click="this.closeOffCanvas">
                  {{ assetType.ObjectName }}
                </router-link>
                <!-- <button v-if="assetType.LinkName === 'meet-the-crew'" class="btn-theme-menu mb-1 w-100" type="button"
                  data-bs-toggle="offcanvas" data-bs-target="#offcanvasCrew" aria-controls="offcanvasCrew">
                  The crew
                </button> -->

              </span>

              <button class="btn-theme-menu py-1 menu-title" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                Enquire Now
              </button>

            </div>
            <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom"
              style="padding:10px; bottom: 0; text-align: right; position: absolute;">
              <img src="/img/ui-theme/peligra.png" alt="Fullscreen"
                style="height: 2em; margin-bottom: 2em; margin-left: 2em;">
              <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen"
                style="height: 1.5em;  margin-bottom: 2em; margin-right: 2em;">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- <OffCanvas /> -->
</template>

<script>
import BunnyNetService from "@/services/BunnyNetService.js";
import global from '../global'
import { unslugify } from "unslugify";
import sortArray from 'sort-array'
import OffCanvas from "@/components/OffCanvas.vue";

export default {
  name: 'DashboardMobile',
  props: [
    'heading'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      componentName: "AssetType1",
      assetTypes: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });

        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.theme.assetsMenuOrder
          }
        })

        this.assetTypes = customMenu
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    closeOffCanvas: function () {
      document.querySelector('.offcanvas-backdrop').remove()
    }
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
};
</script>

<style scoped>
/* .btn-theme-menu {
  width: 60%;
  background: #000;
  color: #fff;
  font-weight: 300;
  margin: auto;
} */
.phone {
  color: #000;
  text-decoration: none;
}
</style>
