<template>
    <div class="mobile mobile-hidden">
        <router-link :to="{
            name: 'DashboardHome'
        }" class="btn position-absolute">
            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
        </router-link>
        <img decoding="async" usemap="#Tennancy" src="/img/ui-theme/updated-tenancys-map.png" alt="Fullscreen"
            style="width: 100%; height:100%" data-recalc-dims="1">
        <p></p>
        <map name="Tennancy">
            <area target="_blank" alt="T12" title="T12" href="https://iframe.mediadelivery.net/play/90575/f77385d1-9196-46b2-aaf5-5f734960baf4" coords="2231,292,2528,805" shape="rect">
            <area target="_blank" alt="T11" title="T11" href="https://iframe.mediadelivery.net/play/90575/bcc2f5cd-c127-4e27-bf5a-3160d21ee6bd" coords="2603,318,2863,786" shape="rect">
            <area target="_blank" alt="T10" title="T10" href="https://iframe.mediadelivery.net/play/90575/6028d62d-6a8d-470e-9b9a-84da1d63d311" coords="2924,575,3197,758" shape="rect">
            <area target="_blank" alt="T9" title="T9" href="https://iframe.mediadelivery.net/play/90575/daf202a7-29f0-485c-be5f-b045c53be39e" coords="3338,297,3532,786" shape="rect">
            <area target="_blank" alt="T8" title="T8" href="https://iframe.mediadelivery.net/play/90575/240f117f-d2d1-4dcb-878e-f1a62a75b326" coords="3711,612,3960,796" shape="rect">
            <area target="_blank" alt="T7" title="T7" href="https://iframe.mediadelivery.net/play/90575/c8e4792b-f8dd-487e-a84c-7892476c22a1" coords="4125,579,4342,782" shape="rect">
            <area target="_blank" alt="T6" title="T6" href="https://iframe.mediadelivery.net/play/90575/20ea29ba-55e1-40f9-ba30-365ea432ebb9" coords="4465,608,4663,810" shape="rect">
            <area target="_blank" alt="T5" title="T5" href="https://iframe.mediadelivery.net/play/90575/d7fdff5f-bcf1-44ce-9296-889657979977" coords="3843,1714,3979,1898" shape="rect">
            <area target="_blank" alt="T2" title="T2" href="https://iframe.mediadelivery.net/play/90575/5c80f7bb-8d11-4752-adc4-74575c16264c" coords="3687,1978,3805,2143" shape="rect">
            <area target="_blank" alt="T3" title="T3" href="https://iframe.mediadelivery.net/play/90575/229f8ed0-d0b8-4901-ada9-e87c740db1f2" coords="3315,1790,3541,2091" shape="rect">
            <area target="_blank" alt="T4" title="T4" href="https://iframe.mediadelivery.net/play/90575/c6dab539-5c36-44a5-9df5-444ad06dece7" coords="3626,1724,3796,1884" shape="rect">
            <area target="_blank" alt="T1" title="T1" href="https://iframe.mediadelivery.net/play/90575/34f6fdf8-799d-49c7-8a05-d8b66ac7ed6c" coords="3861,1978,3989,2124" shape="rect">

            <area target="_blank" alt="T14" title="T14" href="https://iframe.mediadelivery.net/play/90575/2e07bee8-9524-40dc-9e11-00b3c5a37cb7" coords="2368,1790,2585,2030" shape="rect">
            <area target="_blank" alt="T13" title="T13" href="https://iframe.mediadelivery.net/play/90575/f0ed97bb-536d-471b-bb9e-58d9a4900b1a" coords="3527,1177,3720,1333" shape="rect">
            <!-- <area shape="circle" coords="1972,1363,60" href="https://iframe.mediadelivery.net/play/90575/7c2a73ab-fcae-4d15-889f-63f2db280b94" target="_blank" title="T1" alt="T1">
                    <area shape="circle" coords="1866,1363,57" href="https://iframe.mediadelivery.net/play/90575/dfa4e890-e996-4810-b443-89c7a1f4ec75" target="_blank" title="T2" alt="T2">
                    <area shape="circle" coords="1602,1322,78" href="https://iframe.mediadelivery.net/play/90575/9a8284df-20e4-49bb-b455-81767b9b0433" target="_blank" title="T3" alt="T3">
                    <area shape="circle" coords="1822,1208,57" href="https://iframe.mediadelivery.net/play/90575/5c6e0550-1097-44f4-990f-d6258a46f1b3" target="_blank" title="T4" alt="T4">
                    <area shape="circle" coords="1972,1208,60" href="https://iframe.mediadelivery.net/play/90575/0e39a20c-4119-433d-9afd-1254ae70b59d" target="_blank" title="T5" alt="T5">
                    <area shape="circle" coords="2410,472,65" href="#" target="_blank" title="T6" alt="T6">
                    <area shape="circle" coords="2169,428,65" href="#" target="_blank" title="T7" alt="T7">
                    <area shape="circle" coords="1900,428,59" href="#" target="_blank" title="T8" alt="T8">
                    <area shape="circle" coords="1672,428,59" href="#" target="_blank" title="T9" alt="T9">
                    <area shape="circle" coords="1412,428,63" href="#" target="_blank" title="T10" alt="T10">
                    <area shape="circle" coords="1183,350,68" href="#" target="_blank" title="T11" alt="T11">
                    <area shape="circle" coords="966,350,68" href="#" target="_blank" title="T12" alt="T12">
                    <area shape="circle" coords="1775,831,70" href="#" target="_blank" title="T13" alt="T13">
                    <area shape="circle" coords="1016,1256,85" href="#" target="_blank" title="T14" alt="T14"> -->
        </map>
    </div>


    <div class="container d-flex align-items-center justify-content-center z-2">
        <div class="container d-flex align-items-center justify-content-center z-2">
            <div class="row mobile align-content-between g-0 mobile-shown">
                <div class="col-sm-12 col-md-3 h-100" style="background: white; position: relative;">
                    <div class="row g-0">
                        <div class="col-12">
                            <router-link :to="{
                                name: 'DashboardHome'
                            }" class="btn">
                                <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                            </router-link>
                        </div>
                        <div class="col-12">
                            <div class="row g-0">
                                <div class="col-6 col-sm-12" style="text-align: center; ">
                                    <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen"
                                        class="center-app-logo ritz-logo">
                                </div>
                                <div class="col-6 col-sm-12" style="text-align: center;">
                                    <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen"
                                        class="center-app-logo">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 item-section">
                            <div class="row p-0">
                                <!-- <div class="col-12">
                                    <router-link :to="{
                                    name: 'TopLevelPdfViewDetail',
                                    params: {
                                        assetType: 'master-plan'
                                    }
                                    }" class="btn-theme-menu master-plan-button">
                                    Master Plan PDF
                                    </router-link>
                                </div> -->
                                <div class="col-12">
                                    <div class="btn-theme-menu mb-4 menu-title">
                                        Available Tenancies
                                    </div>
                                </div>
                                <div class="col-12" style=" margin-top: 1em;">
                                    <div class="row justify-content-center text-center" style="padding: 0em 6em;">
                                        <div class="col-3" v-for="(item, index) in items">
                                            <a v-bind:href="item" target="_blank" class="btn-theme-menu mb-1 menu-title"
                                                style="padding-left: 15px">{{ index + 1
                                                }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom"
                            style="padding:10px; bottom: 0; text-align: right; position: absolute;">
                            <img src="/img/ui-theme/peligra.png" alt="Fullscreen"
                                style="height: 2em; margin-bottom: 2em; margin-left: 2em;">
                            <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen"
                                style="height: 1.5em;  margin-bottom: 2em; margin-right: 2em;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <OffCanvas /> -->
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'



export default {
    name: "AssetType1",
    props: {
        assetType: String
    },
    components: {
        DashboardVideo,
        OffCanvas
    },
    data() {
        return {
            items: [
                "https://iframe.mediadelivery.net/play/90575/34f6fdf8-799d-49c7-8a05-d8b66ac7ed6c",
                "https://iframe.mediadelivery.net/play/90575/5c80f7bb-8d11-4752-adc4-74575c16264c",
                "https://iframe.mediadelivery.net/play/90575/229f8ed0-d0b8-4901-ada9-e87c740db1f2",
                "https://iframe.mediadelivery.net/play/90575/c6dab539-5c36-44a5-9df5-444ad06dece7",
                "https://iframe.mediadelivery.net/play/90575/d7fdff5f-bcf1-44ce-9296-889657979977",
                "https://iframe.mediadelivery.net/play/90575/20ea29ba-55e1-40f9-ba30-365ea432ebb9",
                "https://iframe.mediadelivery.net/play/90575/c8e4792b-f8dd-487e-a84c-7892476c22a1",
                "https://iframe.mediadelivery.net/play/90575/240f117f-d2d1-4dcb-878e-f1a62a75b326",
                "https://iframe.mediadelivery.net/play/90575/daf202a7-29f0-485c-be5f-b045c53be39e",
                "https://iframe.mediadelivery.net/play/90575/6028d62d-6a8d-470e-9b9a-84da1d63d311",
                "https://iframe.mediadelivery.net/play/90575/bcc2f5cd-c127-4e27-bf5a-3160d21ee6bd",
                "https://iframe.mediadelivery.net/play/90575/f77385d1-9196-46b2-aaf5-5f734960baf4",
                "https://iframe.mediadelivery.net/play/90575/f0ed97bb-536d-471b-bb9e-58d9a4900b1a",
                "https://iframe.mediadelivery.net/play/90575/2e07bee8-9524-40dc-9e11-00b3c5a37cb7"
            ],
            heading: "",
            componentName: "AssetType",
            componentAdmin: "Admin",
            folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/'),
            brochureUrl: "https://florian.metaluxe.com.au/brochure/MCO0024_Master%20brochure%2048pp_320x240_V9%20(2).pdf"
        }
    },
    created() {

    },
    setup() {
        const { theme } = global

        return {
            theme
        }
    }
};
</script>
  