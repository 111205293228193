<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Range -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'range' || viewType == 'renders' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + this.assetType1 + '/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + this.assetType1 + '/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour' || viewType == 'balcony-views' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType1',
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType1 + '/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false
    }
  },
  created() {
    if (this.assetType === '1') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy1Asset.videos
        this.assetNames = this.theme.tenancy1Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy1Asset.floorplans
        this.assetNames = this.theme.tenancy1Asset.floorplansName
        this.assetUrls = this.theme.tenancy1Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy1Asset.panoramas
        this.assetNames = this.theme.tenancy1Asset.panoramasName
        this.assetUrls = this.theme.tenancy1Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy1Asset.balcony
        this.assetNames = this.theme.tenancy1Asset.balconyName
        this.assetUrls = this.theme.tenancy1Asset.balconyUrl
      }else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy1Asset.renders
        this.assetNames = this.theme.tenancy1Asset.rendersName
        this.assetUrls = this.theme.tenancy1Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy1Asset.range
        this.assetNames = this.theme.tenancy1Asset.rangeName
        this.assetUrls = this.theme.tenancy1Asset.rangeUrl
      }
    }
    if (this.assetType === '2') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy2Asset.videos
        this.assetNames = this.theme.tenancy2Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy2Asset.floorplans
        this.assetNames = this.theme.tenancy2Asset.floorplansName
        this.assetUrls = this.theme.tenancy2Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy2Asset.panoramas
        this.assetNames = this.theme.tenancy2Asset.panoramasName
        this.assetUrls = this.theme.tenancy2Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy2Asset.balcony
        this.assetNames = this.theme.tenancy2Asset.balconyName
        this.assetUrls = this.theme.tenancy2Asset.balconyUrl
      }else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy2Asset.renders
        this.assetNames = this.theme.tenancy2Asset.rendersName
        this.assetUrls = this.theme.tenancy2Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy2Asset.range
        this.assetNames = this.theme.tenancy2Asset.rangeName
        this.assetUrls = this.theme.tenancy2Asset.rangeUrl
      }
    }
    if (this.assetType === '3') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy3Asset.videos
        this.assetNames = this.theme.tenancy3Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy3Asset.floorplans
        this.assetNames = this.theme.tenancy3Asset.floorplansName
        this.assetUrls = this.theme.tenancy3Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy3Asset.panoramas
        this.assetNames = this.theme.tenancy3Asset.panoramasName
        this.assetUrls = this.theme.tenancy3Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy3Asset.balcony
        this.assetNames = this.theme.tenancy3Asset.balconyName
        this.assetUrls = this.theme.tenancy3Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy3Asset.renders
        this.assetNames = this.theme.tenancy3Asset.rendersName
        this.assetUrls = this.theme.tenancy3Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy3Asset.range
        this.assetNames = this.theme.tenancy3Asset.rangeName
        this.assetUrls = this.theme.tenancy3Asset.rangeUrl
      }
    }
    if (this.assetType === '4') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy4Asset.videos
        this.assetNames = this.theme.tenancy4Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy4Asset.floorplans
        this.assetNames = this.theme.tenancy4Asset.floorplansName
        this.assetUrls = this.theme.tenancy4Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy4Asset.panoramas
        this.assetNames = this.theme.tenancy4Asset.panoramasName
        this.assetUrls = this.theme.tenancy4Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy4Asset.balcony
        this.assetNames = this.theme.tenancy4Asset.balconyName
        this.assetUrls = this.theme.tenancy4Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy4Asset.renders
        this.assetNames = this.theme.tenancy4Asset.rendersName
        this.assetUrls = this.theme.tenancy4Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy4Asset.range
        this.assetNames = this.theme.tenancy4Asset.rangeName
        this.assetUrls = this.theme.tenancy4Asset.rangeUrl
      }
    }
    if (this.assetType === '5') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy5Asset.videos
        this.assetNames = this.theme.tenancy5Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy5Asset.floorplans
        this.assetNames = this.theme.tenancy5Asset.floorplansName
        this.assetUrls = this.theme.tenancy5Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy5Asset.panoramas
        this.assetNames = this.theme.tenancy5Asset.panoramasName
        this.assetUrls = this.theme.tenancy5Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy5Asset.balcony
        this.assetNames = this.theme.tenancy5Asset.balconyName
        this.assetUrls = this.theme.tenancy5Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy5Asset.renders
        this.assetNames = this.theme.tenancy5Asset.rendersName
        this.assetUrls = this.theme.tenancy5Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy5Asset.range
        this.assetNames = this.theme.tenancy5Asset.rangeName
        this.assetUrls = this.theme.tenancy5Asset.rangeUrl
      }
    }
    if (this.assetType === '6') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy6Asset.videos
        this.assetNames = this.theme.tenancy6Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy6Asset.floorplans
        this.assetNames = this.theme.tenancy6Asset.floorplansName
        this.assetUrls = this.theme.tenancy6Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy6Asset.panoramas
        this.assetNames = this.theme.tenancy6Asset.panoramasName
        this.assetUrls = this.theme.tenancy6Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy6Asset.balcony
        this.assetNames = this.theme.tenancy6Asset.balconyName
        this.assetUrls = this.theme.tenancy6Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy6Asset.renders
        this.assetNames = this.theme.tenancy6Asset.rendersName
        this.assetUrls = this.theme.tenancy6Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy6Asset.range
        this.assetNames = this.theme.tenancy6Asset.rangeName
        this.assetUrls = this.theme.tenancy6Asset.rangeUrl
      }
    }
    if (this.assetType === '7') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy7Asset.videos
        this.assetNames = this.theme.tenancy7Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy7Asset.floorplans
        this.assetNames = this.theme.tenancy7Asset.floorplansName
        this.assetUrls = this.theme.tenancy7Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy7Asset.panoramas
        this.assetNames = this.theme.tenancy7Asset.panoramasName
        this.assetUrls = this.theme.tenancy7Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy7Asset.balcony
        this.assetNames = this.theme.tenancy7Asset.balconyName
        this.assetUrls = this.theme.tenancy7Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy7Asset.renders
        this.assetNames = this.theme.tenancy7Asset.rendersName
        this.assetUrls = this.theme.tenancy7Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy7Asset.range
        this.assetNames = this.theme.tenancy7Asset.rangeName
        this.assetUrls = this.theme.tenancy7Asset.rangeUrl
      }
    }
    if (this.assetType === '8') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy8Asset.videos
        this.assetNames = this.theme.tenancy8Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy8Asset.floorplans
        this.assetNames = this.theme.tenancy8Asset.floorplansName
        this.assetUrls = this.theme.tenancy8Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy8Asset.panoramas
        this.assetNames = this.theme.tenancy8Asset.panoramasName
        this.assetUrls = this.theme.tenancy8Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy8Asset.balcony
        this.assetNames = this.theme.tenancy8Asset.balconyName
        this.assetUrls = this.theme.tenancy8Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy8Asset.renders
        this.assetNames = this.theme.tenancy8Asset.rendersName
        this.assetUrls = this.theme.tenancy8Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy8Asset.range
        this.assetNames = this.theme.tenancy8Asset.rangeName
        this.assetUrls = this.theme.tenancy8Asset.rangeUrl
      }
    }
    if (this.assetType === '9') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy9Asset.videos
        this.assetNames = this.theme.tenancy9Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy9Asset.floorplans
        this.assetNames = this.theme.tenancy9Asset.floorplansName
        this.assetUrls = this.theme.tenancy9Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy9Asset.panoramas
        this.assetNames = this.theme.tenancy9Asset.panoramasName
        this.assetUrls = this.theme.tenancy9Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy9Asset.balcony
        this.assetNames = this.theme.tenancy9Asset.balconyName
        this.assetUrls = this.theme.tenancy9Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy9Asset.renders
        this.assetNames = this.theme.tenancy9Asset.rendersName
        this.assetUrls = this.theme.tenancy9Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy9Asset.range
        this.assetNames = this.theme.tenancy9Asset.rangeName
        this.assetUrls = this.theme.tenancy9Asset.rangeUrl
      }
    }
    if (this.assetType === '10') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy10Asset.videos
        this.assetNames = this.theme.tenancy10Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy10Asset.floorplans
        this.assetNames = this.theme.tenancy10Asset.floorplansName
        this.assetUrls = this.theme.tenancy10Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy10Asset.panoramas
        this.assetNames = this.theme.tenancy10Asset.panoramasName
        this.assetUrls = this.theme.tenancy10Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy10Asset.balcony
        this.assetNames = this.theme.tenancy10Asset.balconyName
        this.assetUrls = this.theme.tenancy10Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy10Asset.renders
        this.assetNames = this.theme.tenancy10Asset.rendersName
        this.assetUrls = this.theme.tenancy10Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy10Asset.range
        this.assetNames = this.theme.tenancy10Asset.rangeName
        this.assetUrls = this.theme.tenancy10Asset.rangeUrl
      }
    }
    if (this.assetType === '11') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy11Asset.videos
        this.assetNames = this.theme.tenancy11Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy11Asset.floorplans
        this.assetNames = this.theme.tenancy11Asset.floorplansName
        this.assetUrls = this.theme.tenancy11Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy11Asset.panoramas
        this.assetNames = this.theme.tenancy11Asset.panoramasName
        this.assetUrls = this.theme.tenancy11Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy11Asset.balcony
        this.assetNames = this.theme.tenancy11Asset.balconyName
        this.assetUrls = this.theme.tenancy11Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy11Asset.renders
        this.assetNames = this.theme.tenancy11Asset.rendersName
        this.assetUrls = this.theme.tenancy11Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy11Asset.range
        this.assetNames = this.theme.tenancy11Asset.rangeName
        this.assetUrls = this.theme.tenancy11Asset.rangeUrl
      }
    }
    if (this.assetType === '12') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy12Asset.videos
        this.assetNames = this.theme.tenancy12Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy12Asset.floorplans
        this.assetNames = this.theme.tenancy12Asset.floorplansName
        this.assetUrls = this.theme.tenancy12Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy12Asset.panoramas
        this.assetNames = this.theme.tenancy12Asset.panoramasName
        this.assetUrls = this.theme.tenancy12Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy12Asset.balcony
        this.assetNames = this.theme.tenancy12Asset.balconyName
        this.assetUrls = this.theme.tenancy12Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy12Asset.renders
        this.assetNames = this.theme.tenancy12Asset.rendersName
        this.assetUrls = this.theme.tenancy12Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy12Asset.range
        this.assetNames = this.theme.tenancy12Asset.rangeName
        this.assetUrls = this.theme.tenancy12Asset.rangeUrl
      }
    }
    if (this.assetType === '13') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy13Asset.videos
        this.assetNames = this.theme.tenancy13Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy13Asset.floorplans
        this.assetNames = this.theme.tenancy13Asset.floorplansName
        this.assetUrls = this.theme.tenancy13Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy13Asset.panoramas
        this.assetNames = this.theme.tenancy13Asset.panoramasName
        this.assetUrls = this.theme.tenancy13Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy13Asset.balcony
        this.assetNames = this.theme.tenancy13Asset.balconyName
        this.assetUrls = this.theme.tenancy13Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy13Asset.renders
        this.assetNames = this.theme.tenancy13Asset.rendersName
        this.assetUrls = this.theme.tenancy13Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy13Asset.range
        this.assetNames = this.theme.tenancy13Asset.rangeName
        this.assetUrls = this.theme.tenancy13Asset.rangeUrl
      }
    }
    if (this.assetType === '14') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy14Asset.videos
        this.assetNames = this.theme.tenancy14Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy14Asset.floorplans
        this.assetNames = this.theme.tenancy14Asset.floorplansName
        this.assetUrls = this.theme.tenancy14Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy14Asset.panoramas
        this.assetNames = this.theme.tenancy14Asset.panoramasName
        this.assetUrls = this.theme.tenancy14Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy14Asset.balcony
        this.assetNames = this.theme.tenancy14Asset.balconyName
        this.assetUrls = this.theme.tenancy14Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy14Asset.renders
        this.assetNames = this.theme.tenancy14Asset.rendersName
        this.assetUrls = this.theme.tenancy14Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy14Asset.range
        this.assetNames = this.theme.tenancy14Asset.rangeName
        this.assetUrls = this.theme.tenancy14Asset.rangeUrl
      }
    }
    if (this.assetType === '15') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy15Asset.videos
        this.assetNames = this.theme.tenancy15Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy15Asset.floorplans
        this.assetNames = this.theme.tenancy15Asset.floorplansName
        this.assetUrls = this.theme.tenancy15Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy15Asset.panoramas
        this.assetNames = this.theme.tenancy15Asset.panoramasName
        this.assetUrls = this.theme.tenancy15Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy15Asset.balcony
        this.assetNames = this.theme.tenancy15Asset.balconyName
        this.assetUrls = this.theme.tenancy15Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy15Asset.renders
        this.assetNames = this.theme.tenancy15Asset.rendersName
        this.assetUrls = this.theme.tenancy15Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy15Asset.range
        this.assetNames = this.theme.tenancy15Asset.rangeName
        this.assetUrls = this.theme.tenancy15Asset.rangeUrl
      }
    }
    if (this.assetType === '16') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.tenancy16Asset.videos
        this.assetNames = this.theme.tenancy16Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.tenancy16Asset.floorplans
        this.assetNames = this.theme.tenancy16Asset.floorplansName
        this.assetUrls = this.theme.tenancy16Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.tenancy16Asset.panoramas
        this.assetNames = this.theme.tenancy16Asset.panoramasName
        this.assetUrls = this.theme.tenancy16Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.tenancy16Asset.balcony
        this.assetNames = this.theme.tenancy16Asset.balconyName
        this.assetUrls = this.theme.tenancy16Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.tenancy16Asset.renders
        this.assetNames = this.theme.tenancy16Asset.rendersName
        this.assetUrls = this.theme.tenancy16Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.tenancy16Asset.range
        this.assetNames = this.theme.tenancy16Asset.rangeName
        this.assetUrls = this.theme.tenancy16Asset.rangeUrl
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array

      console.log(response.data);
      
      const menuItems = response.data.map((item) => {
        if (this.assetUrls && this.assetUrls.length >= 1){
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName,
            title: this.assetNames[j],
            url: this.assetUrls[j],
            id: j++
          };
        } else {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName,
            title: unslugify(item.ObjectName.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
            url: [],
            id: j++
          };
        }
      });

      // Custom order array based on section and type
      if( this.assetOrder && this.assetOrder.length >= 1 ) {
        this.assetTypes = this.reorder(menuItems, this.assetOrder);
      } else {
        this.assetTypes = menuItems
      }

      this.assetTypesReady = true
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>