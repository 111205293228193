<template>
  <!-- <AssetTypeVideo /> -->
  <div class="container h-100 d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
        <div class="col-sm-3 col-m-12 h-100" style="background: white; position: relative;">
                <div class="row g-0">
                    <div class="col-12">
                        <router-link
                            :to="{
                                name:  'AssetType1',
                                params: {
                                    assetType: this.assetType1,
                                }
                            }"
                            class="btn">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </router-link>
                    </div>
                    <div class="col-12">
                        <div class="row g-0">
                            <div class="col-6 col-sm-12" style="text-align: center;">
                                <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen" class="center-app-logo ritz-logo">
                            </div>
                            <div class="col-6 col-sm-12" style="text-align: center;">
                                <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen" class="center-app-logo">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 item-section">
                        <div class="row p-0">
                            <div class="col-12">
                                <div class="btn-theme-menu">
                                    Tenancy {{ this.assetType }}
                                </div>
                            </div>
                            <div class="col-12" style="align-items: center; display: flex; flex-direction: column; margin-top: 2em;" v-if="collection">
                    
                        
                                <template v-for="asset in assetTypes" :key="asset.Guid">
                                    <!-- Video links -->
                                    <router-link
                                        v-if="asset.LinkName === 'videos' || asset.LinkName === 'video'"
                                        :to="{
                                        name:  asset.ComponentName,
                                        params: {
                                            assetType1: this.assetType1,
                                            assetType: this.assetType,
                                            viewType: asset.LinkName,
                                            lid: collection.videoLibraryId,
                                            collectionId: collection.guid
                                        }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        <img :src="theme.videosIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                    </router-link>
                                    
                                    <!-- Panorama links -->
                                    <router-link
                                        v-if="asset.LinkName === 'panoramas' || asset.LinkName === 'virtual-tour' || asset.LinkName === 'balcony-views'"
                                        :to="{
                                        name:  asset.ComponentName,
                                        params: {
                                            assetType1: this.assetType1,
                                            assetType: this.assetType,
                                            viewType: asset.LinkName
                                        }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        <img :src="theme.panoramasIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                    </router-link>
                                    
                                    <!-- Renders links -->
                                    <router-link
                                        v-if="asset.LinkName === 'renders' || asset.LinkName === 'range'"
                                        :to="{
                                        name:  asset.ComponentName,
                                        params: {
                                            assetType1: this.assetType1,
                                            assetType: this.assetType,
                                            viewType: asset.LinkName
                                        }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        <img :src="theme.rendersIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                    </router-link>
                                    
                                    <!-- Floorplans links -->
                                    <router-link
                                        v-if="asset.LinkName === 'floorplans'"
                                        :to="{
                                        name:  asset.ComponentName,
                                        params: {
                                            assetType1: this.assetType1,
                                            assetType: this.assetType,
                                            viewType: asset.LinkName
                                        }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                    </router-link>

                                    <router-link
                                        v-if="asset.LinkName === 'dion-giannarelli-presents' && collection"
                                        :to="{
                                            name:  'AssetVideoViewDetail',
                                            params: {
                                                assetType1: this.assetType1,
                                                guid: collection.guid,
                                                collectionId: collection.videoLibraryId
                                            }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        {{asset.ObjectName}}
                                    </router-link>

                                    <router-link
                                        v-if="asset.LinkName === 'stocklist' || asset.LinkName === 'lease-agreement' || asset.LinkName === 'kennon-presents' || asset.LinkName === 'marriott-bonvoy-presents' || asset.LinkName === 'muzalab-presents'"
                                        :to="{
                                        name:  'AssetPdfViewDetail',
                                        params: {
                                            assetType1: this.assetType1,
                                            assetType: this.assetType,
                                            viewType: asset.LinkName
                                        }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        {{asset.ObjectName}}
                                    </router-link>
                                </template>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom" style="padding:10px; bottom: 0; text-align: right; position: absolute;">
                        <img src="/img/ui-theme/peligra.png" alt="Fullscreen" style="height: 2em; margin-bottom: 2em; margin-left: 2em;">
                        <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen" style="height: 1.5em;  margin-bottom: 2em; margin-right: 2em;">
                    </div>
                </div>
        
            </div>
            <div class="col-sm-9 col-m-0 h-100 mobile-hidden">
                <AssetTypeVideo />
            </div>
    </div>
  </div>
  <!-- <OffCanvas /> -->
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType",
  props: {
    assetType1: String,
    assetType: String
  },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'+ this.assetType1 + "/" + this.assetType + '/'),
      assetOrder: null
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
    .then(response => {
      // Remap array
      console.log(response)
      const menuItems = response.data.map((item) => {
        console.log(item.ObjectName === 'floorplans')
        console.log(item.ObjectName)
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName
        };
      });

      // Assign type menu array
      console.log(this.assetType)
      console.log(this.assetType1)
      console.log('2-bed')
      console.log(this.assetType === '2-bed')
      console.log(menuItems)
      if (this.assetType === '1') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '2') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '3') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '4') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '5') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '6') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '7') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '8') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '9') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '10') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '11') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '12') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '13') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '14') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '15') {
        this.assetOrder = this.theme.tenancyMenuOrder
      } else if (this.assetType === '16') {
        this.assetOrder = this.theme.tenancyMenuOrder
      }

      // Sort array
      const customMenu = sortArray(menuItems, {
        by: ['LinkName'],
        order: 'LinkName',
        customOrders: {
          LinkName: this.assetOrder
        }
      })

      console.log(customMenu)
      
      this.assetTypes = customMenu
      console.log(this.assetTypes)
      console.log("HI WE SORTED")
    })
    .catch(error => {
      console.log(error)
    }),

    // api call get video collection details
    BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
    .then(response => {
        console.log("collections")
      console.log(response)
      this.collection = response.data[0]
    })
    .catch(error => {
      console.log(error)
    })
  },
  setup () {
    // load global config
    const {theme} = global

    return {
      theme
    }
  }
}
</script>