<template>
  <!-- <AssetTypeVideo /> -->
  <div class="container h-100 d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
        <div class="col-sm-3 col-m-12 h-100" style="background: white; position: relative;">
                <div class="row g-0">
                    <div class="col-12">
                        <router-link
                            :to="{
                                name:  'DashboardHome',
                            }"
                            class="btn">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </router-link>
                    </div>
                    <div class="col-12">
                        <div class="row g-0">
                            <div class="col-12" style="text-align: center;">
                                <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen" class="center-app-logo ritz-logo">
                            </div>
                            <div class="col-12" style="text-align: center; color: black; padding: 0 2em;">
                                The Ritz-Carlton, renowned as one of the world's finest luxury hotel brands, is set to make its debut in the precinct by 2026. The opulent establishment will feature 157 exquisite suites, a signature restaurant, all-day dining and lobby bar, as well as a stunning 50-meter rooftop pool, a signature spa and wellness center. In addition, guests will have access to a unique floating beach club and boardwalk, boasting over 8500 square meters of upscale retail and food and beverage options.
                                <br>
                                <br>
                                Each of the 157 luxury rooms will be adorned with state-of-the-art amenities, offering a truly refined experience for guests. Furthermore, guests will have the privilege of enjoying all the exclusive facilities provided by MBSM. The arrival of The Ritz-Carlton is sure to set a new standard of luxury and sophistication in the precinct, promising an unparalleled experience for discerning travelers and visitors alike.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 ritz-item-section">
                        <div class="row p-0">
                            <div class="col-12">
                            </div>
                            <div class="col-12" style="align-items: center; display: flex; flex-direction: column; margin-top: 2em;" v-if="collection">
                    
                        
                                <template v-for="asset in assetTypes" :key="asset.Guid">
                                    <!-- Video links -->
                                    <router-link
                                        v-if="asset.LinkName === 'videos' || asset.LinkName === 'video'"
                                        :to="{
                                        name:  asset.ComponentName,
                                        params: {
                                            assetType1: 'ritz-carlton',
                                            assetType: this.assetType,
                                            viewType: asset.LinkName,
                                            lid: collection.videoLibraryId,
                                            collectionId: collection.guid
                                        }
                                        }"
                                        class="btn-theme-menu mb-2"
                                    >
                                        <img :src="theme.videosIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                    </router-link>
                                </template>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom" style="padding:10px; bottom: 0; text-align: right; position: absolute;">
                        <img src="/img/ui-theme/peligra.png" alt="Fullscreen" style="height: 2em; margin-bottom: 2em; margin-left: 2em;">
                        <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen" style="height: 1.5em;  margin-bottom: 2em; margin-right: 2em;">
                    </div>
                </div>
        
            </div>
            <div class="col-sm-9 col-m-0 h-100 mobile-hidden">
                <AssetTypeVideo />
            </div>
    </div>
  </div>
  <!-- <OffCanvas /> -->
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType",
//   props: {
//     assetType: String
//   },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
        assetType: 'ritz-carlton',
        assetTypes: null,
        collection: null,
        componentName: "AssetView",
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'+ 'ritz-carlton' + "/"),
        assetOrder: null
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
    .then(response => {
      // Remap array
      console.log(response)
      const menuItems = response.data.map((item) => {
        console.log(item.ObjectName === 'floorplans')
        console.log(item.ObjectName)
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName
        };
      });

      // Assign type menu array
      console.log(this.assetType)
    //   console.log(this.assetType1)
      console.log('2-bed')
      console.log(this.assetType === '2-bed')
      console.log(menuItems)
      if (this.assetType === 'ritz-carlton') {
        this.assetOrder = this.theme.ritzCarltonLobbyMenuOrder
      }

      // Sort array
      const customMenu = sortArray(menuItems, {
        by: ['LinkName'],
        order: 'LinkName',
        customOrders: {
          LinkName: this.assetOrder
        }
      })

      console.log(customMenu)
      
      this.assetTypes = customMenu
      console.log(this.assetTypes)
      console.log("HI WE SORTED")
    })
    .catch(error => {
      console.log(error)
    }),

    // api call get video collection details
    BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
    .then(response => {
        console.log("collections")
      console.log(response)
      this.collection = response.data[0]
    })
    .catch(error => {
      console.log(error)
    })
  },
  setup () {
    // load global config
    const {theme} = global

    return {
      theme
    }
  }
}
</script>