<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasRightLabel"></h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row  align-content-center">
        <div class="col-12 h-50 d-flex justify-content-center  flex-column">
          <div class="row">
            <div class="col-12 offset-sm-1 col-sm-8 text-center m-auto py-4 contact-logos">
              <img src="/img/ui-theme/Colliers_Logo_1-Clr_K.png" alt="Patrick Sergi"
                style="height: 8em; margin-bottom: 3em;">
            </div>
            <div class="row mt-2">
              <div class="col-12" style="padding-left: 30px">
                <div class="row px-3 padding-x">

                  <!-- <div class="col-12 col-sm-3 text-center pb-5">
                    <img src="/img/ui-theme/BenLaycock.png" alt="Patrick Sergi" style="height: 11em;">
                    <h4 class="text-black">Ben Laycock</h4>
                    <div class="d-flex flex-row justify-content-center" style="gap: 12px">
                      <a href="#" class="">
                        <img src="/img/ui-theme/phone-icon-rev.svg" alt="Contact">
                      </a>
                      <a href="#">
                        <img src="/img/ui-theme/email-icon-rev.svg" alt="Email">
                      </a>
                    </div>
                  </div> -->

                  <div class="col-12 col-md-4 pb-5 px-3">
                    <div class="card card-m-1">
                      <div class="card-body d-flex">
                        <img src="/img/ui-theme/Michael Tuck 2.png" alt="Patrick Sergi" style="height: 8em;">
                        <div class="d-flex flex-column" style="padding-left: 15px; padding-right: 29px">
                          <h5 class="text-black">Michael Tuck</h5><br>
                          <h5 class="text-black">Head of Retail Leasing ANZ</h5><br>
                          <h5 class="text-black">0406 234 106</h5><br>
                        </div>
                      </div>
                      <a href="tel:0406234106">
                        <div class="card-footer">
                          <h5 class="text-white text-center mb-0">CONTACT EXPERT</h5>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-12 col-md-4 pb-5 px-3">
                    <div class="card card-m-2">
                      <div class="card-body d-flex">
                        <img src="/img/ui-theme/Ishbel Jones 2.png" alt="Ishbel Jones" style="height: 8em;">
                        <div class="d-flex flex-column" style="padding-left: 15px;">
                          <h5 class="text-black">Ishbel Jones</h5><br>
                          <h5 class="text-black">Senior Executive Retail Leasing</h5><br>
                          <h5 class="text-black">0431 772 510</h5><br>
                        </div>
                      </div>
                      <a href="tel:0431772510">
                        <div class="card-footer">
                          <h5 class="text-white text-center mb-0">CONTACT EXPERT</h5>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-12 col-md-4 pb-5 px-3">
                    <div class="card card-m-3">
                      <div class="card-body d-flex">
                        <img src="/img/ui-theme/ChloeLonergan 2.png" alt="ChloeLonergan" style="height: 8em;">
                        <div class="d-flex flex-column" style="padding-left: 18px; padding-right: 29px;">
                          <h5 class="text-black">Chloe Lonergan</h5><br>
                          <h5 class="text-black">Executive Retail Leasing</h5><br>
                          <h5 class="text-black">0411 264 819</h5><br>
                        </div>
                      </div>
                      <a href="tel:0411264819">
                        <div class="card-footer">
                          <h5 class="text-white text-center mb-0">CONTACT EXPERT</h5>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row w-100" id="contact-text">
            <div class="col-12 offset-sm-1 col-sm-10 pt-5 text-center text-black px-5">
              <p style="font-size: 12px;">This document/email has been prepared by Colliers for advertising and general
                information only.
                Colliers
                makes no guarantees, representations or warranties of any kind, expressed or implied, regarding the
                information including, but not limited to, warranties of content, accuracy and reliability. Any
                interested
                party should undertake their own inquiries as to the accuracy of the information. Colliers excludes
                unequivocally all inferred or implied terms, conditions and warranties arising out of this document and
                excludes all liability for loss and damages arising there from. This publication is the copyrighted
                property of Colliers and/or its licensor(s). © 2023. All rights reserved. This communication is not
                intended to cause or induce breach of an existing listing agreement.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OffCanvas',
  props: [
    'heading'
  ],
  data() {
    return {

    }
  }
};
</script>

<style scoped>
h5 {
  font-family: 'Sweet Sans Pro'
}

@media only screen and (min-width : 1875px) {
  .padding-x {
    padding: 0 180px !important;
  }

  .card-m-1 {
    margin-right: 20px;
  }

  .card-m-2 {
    margin-right: 10px;
    margin-left: 10px;
  }

  .card-m-3 {
    margin-left: 20px;
  }
}


.card {
  border: 3px solid #6e6e6e;
}

.btn-theme-menu {
  width: 60%;
  background: #000;
  color: #fff;
  font-weight: 300;
  margin: auto;
}

.card-footer:last-child {
  border-radius: 0;
}

.card-footer {
  background-color: #6e6e6e;
  border-radius: 0;
}

.phone {
  color: #000;
  text-decoration: none;
}

.border {
  border: 1px solid black
}
</style>
