<template>

  <!-- Top navigation -->
  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-center v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset"></button>
        </div>
      </div>
    </div>
  </div>

  <!-- Renders -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'renders'">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + currentRender.ObjectName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour'">
    <iframe src="https://charlieparker.metaluxe.com.au/assets/apartment-type-2c/virtual-tour/tour/index.html" title="" width="100%" height="100%"></iframe>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans'">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + currentRender.ObjectName" class="h-100">
        </div>
      </div>
    </div>
    <!-- <div id="adobe-dc-view"></div> -->
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      file: null,
      fileTemp: null,
      fileName: null,
      pdfAPIReady: false
    }
  },
  created() {
    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          id: j++
        };
      });

      // Custom order array based on section and type
      if (this.assetType === 'introduction') {
        switch(this.viewType) {
          case 'renders':
            this.assetTypes = this.reorder(menuItems, this.theme.introductionAsset.renders);
            break;
          case 'panoramas':
            this.assetTypes = this.reorder(menuItems, this.theme.introductionAsset.panoramas);
            break;
          case 'floorplans':
            this.assetTypes = this.reorder(menuItems, this.theme.introductionAsset.floorplans);
            // this.pdfFilename();
            break;
          default:
            this.assetTypes = menuItems;
        }
      } else if (this.assetType === 'penthouse') {
        switch(this.viewType) {
          case 'renders':
            this.assetTypes = this.reorder(menuItems, this.theme.penthouseAsset.renders);
            break;
          case 'panoramas':
            this.assetTypes = this.reorder(menuItems, this.theme.penthouseAsset.panoramas);
            break;
          case 'floorplans':
            this.assetTypes = this.reorder(menuItems, this.theme.penthouseAsset.floorplans);
            // this.pdfFilename();
            break;
          default:
            this.assetTypes = menuItems;
        }
      } else if (this.assetType === 'sub-penthouse') {
        switch(this.viewType) {
          case 'renders':
            this.assetTypes = this.reorder(menuItems, this.theme.subPenthouseAsset.renders);
            break;
          case 'panoramas':
            this.assetTypes = this.reorder(menuItems, this.theme.subPenthouseAsset.panoramas);
            break;
          case 'floorplans':
            this.assetTypes = this.reorder(menuItems, this.theme.subPenthouseAsset.floorplans);
            // this.pdfFilename();
            break;
          default:
            this.assetTypes = menuItems;
        }
      } else if (this.assetType === '3-bed-apartment') {
        switch(this.viewType) {
          case 'renders':
            this.assetTypes = this.reorder(menuItems, this.theme.bed3Asset.renders);
            break;
          case 'panoramas':
            this.assetTypes = this.reorder(menuItems, this.theme.bed3Asset.panoramas);
            break;
          case 'floorplans':
            this.assetTypes = this.reorder(menuItems, this.theme.bed3Asset.floorplans);
            // this.pdfFilename();
            break;
          default:
            this.assetTypes = menuItems;
        }
      } else if (this.assetType === 'amenities') {
        switch(this.viewType) {
          case 'renders':
            this.assetTypes = this.reorder(menuItems, this.theme.amenitiesAsset.renders);
            break;
          case 'panoramas':
            this.assetTypes = this.reorder(menuItems, this.theme.amenitiesAsset.panoramas);
            break;
          case 'floorplans':
            this.assetTypes = this.reorder(menuItems, this.theme.amenitiesAsset.floorplans);
            // this.pdfFilename();
            break;
          default:
            this.assetTypes = menuItems;
        }
      }
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },

    // Assign PDF filename and display name
    pdfFilename: function() {
      this.fileTemp = this.assetTypes[this.order].LinkName;
      this.file = this.fileTemp.substr(0, this.fileTemp.lastIndexOf(".")) + ".pdf";
      this.fileName = this.assetTypes[this.order].ObjectName;
      this.fileName = this.fileName.substr(0, this.fileName.lastIndexOf(".")) + ".pdf";

      this.renderPdf(this.theme.cdnUrl, '/assets/' + this.assetType + '/' + this.viewType, this.file, this.fileName);
    },

    // Render PDF using Adobe Embed API
    renderPdf: function(cdn, path, file, fileName) {
      if (file) this.pdfAPIReady = true;

      if (window.AdobeDC) {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          var adobeDCView = new AdobeDC.View({
            clientId: "ac43a5123a9e428c8af210bd3f3a1ac0",
            divId: "adobe-dc-view"
          })
          adobeDCView.previewFile({
            content: {
              location: {
                url: cdn + path + "/pdfs/" + file
              }
            },
            metaData: {
              fileName: fileName
            }
          },
          {
            defaultViewMode: "FIT_WIDTH",
            showAnnotationTools: false,
            showLeftHandPanel: false,
            showPageControls: false,
            showDownloadPDF: false,
            showPrintPDF: false
          })
        });
      }
    }
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js')
    document.head.appendChild(externalScript)
  },
  computed: {
    currentRender() {
      let tempRender = this.assetTypes;
      
      tempRender = tempRender.filter((item) => {
        return (item.id == this.order)
      })
      
      return tempRender[0];
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>