<template>

  <!-- <OffCanvas /> -->
  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-center v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset-rev"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="videoStatus">
    <iframe :src="`https://iframe.mediadelivery.net/embed/${videoCollectionId}/${videoGuid}?autoplay=true&loop=true`"
      loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"></iframe>
  </div>
</template>

<script>
import DetailPDFTopNav from '@/components/DetailPDFTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
    name: "TopLevelVideoViewDetail",
    props: [
        'assetType',
        // 'viewType',
        // 'order'
    ],
    components: {
        DetailPDFTopNav,
        OffCanvas
    },
    data() {
        return {
            assetTypes: null,
            assetOrder: null,
            currentAsset: null,
            folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + "/"),
            componentName: "AssetView",
            assetTypesReady: false,
            videoCollectionId: null,
            videoGuid: null,
            videoStatus: false

        }
    },
    created() {
        // this.assetOrder = this.theme.introductionAsset.brochures
        // this.assetNames = this.theme.introductionAsset.brochuresName
        // this.assetUrls = this.theme.introductionAsset.brochuresUrl
        BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
        .then(response => {
            console.log("collections")
            console.log(response)
            this.collection = response.data[0]
            BunnyNetService.getVideos(response.data[0].videoLibraryId, response.data[0].guid)
            .then(response2 => {
                // Remap array (inject asset names)
                console.log(response2)
                console.log(response2.data.items[0])
                this.videoCollectionId = response2.data.items[0].videoLibraryId
                this.videoGuid = response2.data.items[0].guid
                this.videoStatus = true
            })
            .catch(error => {
                console.log(error)
            })
            
        })
        .catch(error => {
            console.log(error)
        })
    },
    setup () {
        const {theme} = global

        return {
        theme
        }
    },
}
</script>