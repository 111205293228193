import { reactive } from "vue";

const theme = reactive({
  agentLogo: "mrcb-dbi-lockup-logo.svg",
  devLogo: "mbsm-dashboard-logo.png",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "0ac4a7d3-281f-48be-bfa9-97eb5af07b5b",
  videoLibraryId: "90575",
  streamCdnUrl: "https://vz-b10785f4-57e.b-cdn.net",
  streamApiKey: "cc3718db-c1c6-4869-982a9df1b148-f903-4f72",
  storageApiPassword: "396807cf-fc70-42b6-9444ac3720d6-3c1f-41f4",
  cdnUrl: "https://dgdemo.metaluxe.com.au",
  devFolder: "/bgdemo",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

  // top level menu
  assetsMenuOrder: [
    'the-project',
    'ritz-carlton',
    'main-beach-superyacht-marina',
    'retail-piazza',
    'vr-tour',
    'master-plan',
    'available-tenancies',
    'meet-the-crew',
    'contact',
    'precinct'
  ],
  availableTenanciesMenuOrder: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
  ],

  tenancyMenuOrder: [
    'video',
    'videos',
    'virtual-tour',
    'balcony-views',
    'range',
    'renders',
    'floorplans',
  ],
  precinctMenuOrder: [
    'video',
    'videos',
    'virtual-tour',
    'balcony-views',
    'range',
    'renders',
    'render',
    'floorplans',
  ],
  ritzCarltonLobbyMenuOrder: [
    'video',
    'videos',
    'virtual-tour',
    'balcony-views',
    'range',
    'renders',
    'floorplans',
  ],
  superyachtMarinaMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'range',
    'renders',
    'floorplans',
  ],

  precinctLobbyAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Lobby Virtual Tour'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/C3v6RofyB'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  ritzCarltonLobbyAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Lobby Virtual Tour'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/C3v6RofyB'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  superyachtMarinaAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Marina Virtual Tour'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/17RajhlyI'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  tenancy1Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 1 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/zwMA1q452vZ',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy2Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 2 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/4fIQsl1O5Zf',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy3Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 3 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/MyXPbhWLp_O',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy4Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 4 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/3-hbH5CIg-c',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy5Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 5 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/hacfdfORbjg',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy6Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 6 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/vEAWKcODTtp',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy7Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 7 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/lTP8cYTgaR1',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy8Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 8 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/fcasUOxJJ3V',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy9Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 9 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/AXyG5HoSDC',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy10Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 10 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/bR8VeI7PTDn',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy11Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 11 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/eqUWzjyd4jr',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy12Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 12 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/aYLL-y43yE7',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy13Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 13 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/sPRZX1atrs',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy14Asset: {
    videos: [],
    videosName: [
    ],
    panoramas: [0],
    panoramasName: [
      'Tenancy 14 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/XlPfUypflYd',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy15Asset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Tenancy 15 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/Lj57s7LTGNp',
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  tenancy16Asset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Tenancy 16 Virtual Tour',
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/r8KeqkLgT-9'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
});

export default { theme };