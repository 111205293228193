<template>
  <div class="container d-flex align-items-center justify-content-center z-2 ">
    <div class="row mobile page-section g-0">
      <div class="row g-0 mobile-hidden h-100">
        <div class="col-sm-3 col-m-12 menu-section" style="background: white; position: relative; ">
          <div class="row g-0">
            <div class="col-12 d-flex justify-content-between">
              <router-link :to="{
                name: 'Login'
              }" class="btn" style="opacity: 0">
                <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
              </router-link>
              <button class="btn mobile-shown" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu"
                aria-controls="offcanvasMenu">
                <img src="/img/ui-theme/menu-icon-black.svg" alt="Fullscreen">
              </button>
            </div>
            <div class="col-12 dashboard-logo-section">
              <div class="row g-0">
                <div class="col-12" style="text-align: center;">
                  <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen" class="center-dashboard-logo">
                </div>
              </div>
              <div class="row g-0">
                <div class="col-12" style="text-align: center;">
                  <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen" class="center-dashboard-logo">
                </div>
              </div>
            </div>
            <div class="col-12 item-section mobile-hidden">
              <span v-for="assetType in assetTypes">

                <a href="/asset/available-tenancies" v-if="assetType.LinkName === 'available-tenancies'"
                  :key="assetType.Guid" class="btn-theme-menu mb-1">
                  {{ assetType.ObjectName }}
                </a>

                <a href="/main-beach-superyacht-marina" v-if="assetType.LinkName === 'main-beach-superyacht-marina'"
                  :key="assetType.Guid" class="btn-theme-menu mb-1 text-center" style="line-height: 1.2">
                  {{ assetType.ObjectName }}
                </a>

                <!-- <router-link v-if="assetType.LinkName === 'retail-piazza'" :key="assetType.Guid" :to="{
                name: 'RetailPiazzaAssetType',
                params: {
                  assetType: assetType.LinkName
                }
              }" class="btn-theme-menu mb-1">
                {{ assetType.ObjectName }}
              </router-link> -->
                <a href="https://kuula.co/share/collection/7FWbX?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1"
                  target="_blank" v-if="assetType.LinkName === 'vr-tour'" :key="assetType.Guid"
                  class="btn-theme-menu mb-1">
                  VR Tour
                </a>
                <router-link v-if="assetType.LinkName === 'ritz-carlton'" :key="assetType.Guid" :to="{
                  name: 'RitzCarltonAssetType',
                  params: {
                    assetType: assetType.LinkName
                  }
                }" class="btn-theme-menu mb-1">
                  {{ assetType.ObjectName }}
                </router-link>
                <!-- <button v-if="assetType.LinkName === 'meet-the-crew'" class="btn-theme-menu mb-1 w-100" type="button"
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasCrew" aria-controls="offcanvasCrew">
                The crew
              </button> -->
                <!-- <router-link
                        v-if="assetType.LinkName === 'precinct'"
                        :key="assetType.Guid"
                        :to="{
                        name:  'MainBeachSuperyachtMarinaAssetType',
                        params: {
                            assetType: assetType.LinkName,
                        }
                        }"
                        class="btn-theme-menu mb-1">
                            {{ assetType.ObjectName }}
                    </router-link> -->

              </span>

              <button class="btn-theme-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight">
                Enquire Now
              </button>

            </div>
            <div class="col-12 mobile-hidden align-items-center justify-content-around sticky-bottom"
              style="padding:10px; bottom: 0; text-align: right; position: absolute;">
              <img src="/img/ui-theme/peligra.png" alt="Fullscreen"
                style="height: 2em; margin-bottom: 2em; margin-left: 2em;">
              <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen"
                style="height: 1.5em;  margin-bottom: 2em; margin-right: 2em;">
            </div>
          </div>

        </div>
        <div class="col-sm-9 col-m-0 video-section mobile-hdidden">
          <DashboardVideo />
        </div>
      </div>
      <div class="mobile-shown h-100">
        <DashboardMobile />
      </div>
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import DashboardMobile from "@/components/DashboardMobile.vue";
import OffCanvasCrew from "@/components/OffCanvasCrew.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "DashboardHome",
  components: {
    DashboardVideo,
    DashboardMobile,
    OffCanvasCrew,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      componentName: "AssetType1",
      componentAdmin: "Admin",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
      brochureUrl: "https://florian.metaluxe.com.au/brochure/MCO0024_Master%20brochure%2048pp_320x240_V9%20(2).pdf"
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });

        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.theme.assetsMenuOrder
          }
        })

        this.assetTypes = customMenu
      })
      .catch(error => {
        console.log(error)
      })
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
};
</script>
