<template>
  <div class="container h-100 d-flex align-items-center justify-content-center z-2" id="main-beach">
    <div class="row mobile align-content-between g-0">
      <div class="col-m-12 h-100" style="background: white; position: relative;">
        <div class="row g-0">
          <div class="col-12">
            <router-link :to="{
              name: 'DashboardHome'
            }" class="btn">
              <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
            </router-link>

          </div>
          <div class="col-12">
            <div class="row g-0">
              <div class="col-6 col-sm-12" style="text-align: center;">
                <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen" class="center-app-logo ritz-logo">
              </div>
              <div class="col-6 col-sm-12" style="text-align: center;">
                <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen" class="center-app-logo">
              </div>
            </div>
          </div>
          <div class="col-12 item-section">
            <span v-for="assetType in assetTypes" :key="assetType.Guid">
              <span v-if="assetType.LinkName === 'main-beach-superyacht-marina'">
                <div class="btn-theme-menu mb-4 menu-title">
                  Main MainBeach Superyacht Marina
                </div>

                <router-link v-if="assetType.LinkName === 'main-beach-superyacht-marina'" :key="assetType.Guid" :to="{
                  name: 'TopLevelAssetView',
                  params: {
                    assetType1: assetType.LinkName,
                    viewType: 'video'
                  }
                }" class="btn-theme-menu menu-title" @click="this.closeOffCanvas">
                  Videos
                </router-link>
              </span>
            </span>
            <a href="https://kuula.co/share/collection/7FCWK?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank"
              class="btn-theme-menu menu-title">ARM A</a>
            <a href="https://kuula.co/share/collection/7FC3k?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1"
              target="_blank" class="btn-theme-menu menu-title">ARM B</a>
            <a href="https://kuula.co/share/collection/7FxPY?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank"
              class="btn-theme-menu menu-title">ARM D</a>


          </div>
          <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom"
            style="padding:10px; bottom: 0; text-align: right; position: absolute;">
            <img src="/img/ui-theme/peligra.png" alt="Fullscreen"
              style="height: 2em; margin-bottom: 2em; margin-left: 2em;">
            <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen"
              style="height: 1.5em;  margin-bottom: 2em; margin-right: 2em;">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BunnyNetService from "@/services/BunnyNetService.js";
import global from '../global'
import { unslugify } from "unslugify";
import sortArray from 'sort-array'
import OffCanvas from "@/components/OffCanvas.vue";

export default {
  name: 'DashboardMobile',
  props: [
    'heading'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      componentName: "AssetType1",
      assetTypes: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });

        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.theme.assetsMenuOrder
          }
        })

        this.assetTypes = customMenu
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    closeOffCanvas: function () {
      document.querySelector('.offcanvas-backdrop').remove()
    }
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
};
</script>

<style scoped>
/* .btn-theme-menu {
  width: 60%;
  background: #000;
  color: #fff;
  font-weight: 300;
  margin: auto;
} */
.phone {
  color: #000;
  text-decoration: none;
}
</style>
