<template>
  <div class="position-absolute p-header">
    <div class="col-12">
      <div class="row g-0">
        <div class="col-12 d-flex align-items-center justify-content-end theme-header">
          <div @click="$router.go(-1)" class="btn">
            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
          </div>
          <p class="m-auto text-black" style="text-transform: uppercase;">{{ this.displayHeading }}</p>
          <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight">
            <img src="/img/ui-theme/contact-icon.svg" alt="Fullscreen">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '../global'
import { unslugify } from "unslugify";

export default {
  name: 'ViewTopNav',
  props: [
    'heading',
    'subheading',
    'subsubheading',
  ],
  data() {
    return {
      componentName: "DashboardHome"
    }
  },
  setup() {
    const { theme } = global
    let displayHeading = ""

    return {
      theme, displayHeading
    }
  },
  created() {
    this.displayHeading = unslugify(this.subheading)
  }
};
</script>
