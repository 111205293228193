<template>
  <div class="mobile-shown">
    <MainBeachSuperyachtMarinaAssetTypeMobile />
  </div>
  <div class="mobile-hidden">
    <MainBeachSuperyachtMarinaAssetTypeDesktop />
  </div>
</template>

<script>
import MainBeachSuperyachtMarinaAssetTypeMobile from "@/components/MainBeachSuperyachtMarinaAssetTypeMobile.vue";
import MainBeachSuperyachtMarinaAssetTypeDesktop from "@/components/MainBeachSuperyachtMarinaAssetTypeDesktop.vue";

import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType",
  //   props: {
  //     assetType: String
  //   },
  components: {
    MainBeachSuperyachtMarinaAssetTypeDesktop,
    MainBeachSuperyachtMarinaAssetTypeMobile,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
      assetType: 'main-beach-superyacht-marina',
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + 'main-beach-superyacht-marina' + "/"),
      assetOrder: null
    }
  },
  created() {

  },
  setup() {
    // load global config
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
