<template>
  <TopLevelViewTopNav :heading="this.viewType" :subheading="this.assetType1" />
  
  <div class="position-absolute p-content z-1">
			<div class="col-12 p-0 z-1 h-100">
				<div class="row g-0 h-100">

          <div class="col-12 col-md-6 col-lg-4 m-auto" v-for="asset in assetTypes" :key="asset.guid">
						<div class="row g-0">
							<div class="col-12 position-relative z-1">

                                <!-- Videos -->
                                <router-link
                                v-if="viewType === 'videos' || viewType === 'video'"
                                :to="{
                                    name:  'VideoView',
                                    params: {
                                    guid: asset.guid,
                                    collectionId: asset.videoLibraryId
                                    }
                                }"
                                class="v-link"
                                >
                                    <img :src="theme.streamCdnUrl + '/' + asset.guid + '/' + asset.thumbnailFileName + '?width=700'" class="img-fluid">
                                    <div class="render-name">
                                        {{ asset.title }}
                                    </div>
                                </router-link>

                                <!-- Renders -->
                                <router-link
                                v-if="viewType === 'renders' || viewType === 'range'"
                                :to="{
                                    name:  'AssetViewDetail',
                                    params: {
                                    assetType: this.assetType,
                                    viewType: this.viewType,
                                    order: asset.id
                                    }
                                }"
                                class="r-link"
                                >
                                    <img :src="theme.cdnUrl + '/assets/' + this.assetType1 + '/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                                    <div class="render-name">
                                        {{ asset.title }}
                                    </div>
                                </router-link>

                                <!-- Panoramas -->
                                <router-link
                                v-if="viewType === 'panoramas' || viewType === 'virtual-tour' || viewType === 'balcony-views'"
                                :to="{
                                    name:  'AssetViewDetail',
                                    params: {
                                    assetType: this.assetType,
                                    viewType: this.viewType,
                                    order: asset.id
                                    }
                                }"
                                class="p-link"
                                >
                                    <img :src="theme.cdnUrl + '/assets/' + this.assetType1 + '/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                                    <div class="render-name">
                                        {{ asset.title }}
                                    </div>
                                </router-link>

                                <!-- Floorplans -->
                                <router-link
                                v-if="viewType === 'floorplans'"
                                :to="{
                                    name:  'AssetViewDetail',
                                    params: {
                                    assetType: this.assetType,
                                    viewType: this.viewType,
                                    order: asset.id
                                    }
                                }"
                                class="f-link"
                                >
                                    <img :src="theme.cdnUrl + '/assets/' + this.assetType1 + '/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                                    <div class="render-name">
                                        {{ asset.title }}
                                    </div>
                                </router-link>

							</div>
						</div>
					</div>
          
      </div>
    </div>
  </div>
  
  <ViewBotNav />
  <!-- <OffCanvas /> -->
</template>

<script>
import TopLevelViewTopNav from '@/components/TopLevelViewTopNav.vue';
import ViewBotNav from '@/components/ViewBotNav.vue';
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global';
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetView",
  props: [
    'assetType1',
    'assetType',
    'viewType',
    'lid',
    'collectionId'
  ],
  components: {
    TopLevelViewTopNav,
    ViewBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType1 + '/' + this.viewType + '/'),
      videoStatus: false,
      assetNames: null,
      assetUrls: null
    }
  },
  created() {
    if (this.assetType === 'main-beach-superyacht-marina') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.superyachtMarinaAsset.videos
        this.assetNames = this.theme.superyachtMarinaAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.superyachtMarinaAsset.floorplans
        this.assetNames = this.theme.superyachtMarinaAsset.floorplansName
        this.assetUrls = this.theme.superyachtMarinaAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.superyachtMarinaAsset.panoramas
        this.assetNames = this.theme.superyachtMarinaAsset.panoramasName
        this.assetUrls = this.theme.superyachtMarinaAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.superyachtMarinaAsset.balcony
        this.assetNames = this.theme.superyachtMarinaAsset.balconyName
        this.assetUrls = this.theme.superyachtMarinaAsset.balconyUrl
      }else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.superyachtMarinaAsset.renders
        this.assetNames = this.theme.superyachtMarinaAsset.rendersName
        this.assetUrls = this.theme.superyachtMarinaAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.superyachtMarinaAsset.range
        this.assetNames = this.theme.superyachtMarinaAsset.rangeName
        this.assetUrls = this.theme.superyachtMarinaAsset.rangeUrl
      }
    }
    if (this.assetType === 'ritz-carlton-gc') {
      if (this.viewType === 'videos' || this.viewType === 'video') {
        this.assetOrder = this.theme.ritzCarltonLobbyAsset.videos
        this.assetNames = this.theme.ritzCarltonLobbyAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.ritzCarltonLobbyAsset.floorplans
        this.assetNames = this.theme.ritzCarltonLobbyAsset.floorplansName
        this.assetUrls = this.theme.ritzCarltonLobbyAsset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.ritzCarltonLobbyAsset.panoramas
        this.assetNames = this.theme.ritzCarltonLobbyAsset.panoramasName
        this.assetUrls = this.theme.ritzCarltonLobbyAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.ritzCarltonLobbyAsset.balcony
        this.assetNames = this.theme.ritzCarltonLobbyAsset.balconyName
        this.assetUrls = this.theme.ritzCarltonLobbyAsset.balconyUrl
      }else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.ritzCarltonLobbyAsset.renders
        this.assetNames = this.theme.ritzCarltonLobbyAsset.rendersName
        this.assetUrls = this.theme.ritzCarltonLobbyAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.ritzCarltonLobbyAsset.range
        this.assetNames = this.theme.ritzCarltonLobbyAsset.rangeName
        this.assetUrls = this.theme.ritzCarltonLobbyAsset.rangeUrl
      }
    }
    



    // Video type
    if(this.viewType === 'videos' || this.viewType === 'video') {
        BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType1)
        .then(response => {
            console.log("collections")
            console.log(response)
            this.collection = response.data[0]
            BunnyNetService.getVideos(response.data[0].videoLibraryId, response.data[0].guid)
            .then(response => {
                // Remap array (inject asset names)
                console.log(response)
                let j = 0;
                const menuItems = response.data.items.map((item) => {
                    if (this.assetUrls && this.assetUrls.length >= 1){
                        return {
                            guid: item.guid,
                            videoLibraryId: item.videoLibraryId,
                            thumbnailFileName: item.thumbnailFileName,
                            ObjectName: item.ObjectName,
                            title: this.assetNames[j],
                            id: j++
                        };
                    } else {
                        return {
                            guid: item.guid,
                            videoLibraryId: item.videoLibraryId,
                            thumbnailFileName: item.thumbnailFileName,
                            ObjectName: item.ObjectName,
                            title: unslugify(item.title.split('.').slice(0,-1).join(".")).replace("hyphen", "-"),
                            id: j++
                        };
                    }
                });

                // Custom order array
                if ( this.assetOrder && this.assetOrder.length >= 1 ) {
                this.assetTypes = this.reorder(menuItems, this.assetOrder);
                } else {
                this.assetTypes = menuItems;
                }

                this.videoStatus = true
            })
            .catch(error => {
                console.log(error)
            })
            
        })
        .catch(error => {
            console.log(error)
        })
    // Renders, Panoramas, Floorplans type
    } else {
      BunnyNetService.getRenders(this.folderStructure, this.assetType)
      .then(response => {
        let j = 0;
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          if (this.assetUrls && this.assetUrls.length >= 1){
            return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: this.assetNames[j],
              url: this.assetUrls[j],
              id: j++
            };
          }else {
			return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: unslugify(item.ObjectName.split('.').slice(0,-1).join(".")).replace("hyphen", "-"),
              url: [],
              id: j++
            };
          }
        });

        // Custom order array
        if( this.assetOrder && this.assetOrder.length >= 1 ) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems
        }
      })
    }
    
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>
