<template>
  <!-- <AssetTypeVideo /> -->
  <div class="container h-100 d-flex align-items-center justify-content-center z-2" id="main-beach">
    <div class="row mobile align-content-between g-0">
      <div class="col-sm-3 col-m-12 h-100" style="background: white; position: relative;">
        <div class="row g-0">
          <div class="col-12">
            <router-link :to="{
              name: 'DashboardHome',
            }" class="btn">
              <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
            </router-link>
          </div>
          <div class="col-12">
            <div class="row g-0">
              <div class="col-12 mb-logo" style="text-align: center;">
                <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen" class="center-app-logo ritz-logo">
              </div>
              <div class="col-12 text-font-size" style="text-align: center; color: black; padding: 0 2em;">
                Introducing a groundbreaking concept in luxury, the Main Beach Superyacht Marina (MBSM) sets the standard
                for 6-star white glove amenities in Australia's premier nautical destination and luxury lifestyle
                precinct. Boasting an impressive array of world-class facilities, including a state-of-the-art yachting
                harbor, a prestigious hotel, and a high-end retail center, the MBSM offers an unparalleled experience.
                <br>
                <br>
                With 66 exclusive superyacht berths available for lease, accommodating vessels of up to 73 meters in
                length, the MBSM provides a haven for discerning yacht owners seeking the utmost in sophistication and
                service. This visionary marina represents the epitome of luxury living, offering a refined and
                unparalleled experience for those who appreciate the finer things in life. Discover the pinnacle of
                maritime indulgence at the Main Beach Superyacht Marina, where uncompromising luxury meets world-class
                hospitality.
              </div>
            </div>
          </div>
          <div class="col-12 main-beach-item-section">
            <div class="row p-0">
              <div class="col-12">
              </div>
              <div class="col-12" style="align-items: center; display: flex; flex-direction: column; " v-if="collection">


                <template v-for="asset in assetTypes" :key="asset.Guid">
                  <!-- Video links -->
                  <router-link v-if="asset.LinkName === 'videos' || asset.LinkName === 'video'" :to="{
                    name: asset.ComponentName,
                    params: {
                      assetType1: 'main-beach-superyacht-marina',
                      assetType: this.assetType,
                      viewType: asset.LinkName,
                      lid: collection.videoLibraryId,
                      collectionId: collection.guid
                    }
                  }" class="btn-theme-menu mb-2">
                    <img :src="theme.videosIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
                  </router-link>
                </template>

              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-9 col-m-0 h-100 mobile-hidden position-relative">
        <img decoding="async" usemap="#Yacht" src="/img/ui-theme/mbsm map.png" alt="Fullscreen" style="width: 100%"
          data-recalc-dims="1">
        <p></p>
        <map name="Yacht">
          <area shape="rect" coords="561,96,108,181"
            href="https://kuula.co/share/5NRJk?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A1" alt="A1">
          <area shape="rect" coords="419,193,146,255"
            href="https://kuula.co/share/5NRJ4?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A2" alt="A2">
          <area shape="rect" coords="409,279,95,336"
            href="https://kuula.co/share/5NRFM?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A3" alt="A3">
          <area shape="rect" coords="386,362,72,439"
            href="https://kuula.co/share/5NRFF?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A4" alt="A4">
          <area shape="rect" coords="389,455,16,525"
            href="https://kuula.co/share/5NR9z?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A5" alt="A5">
          <area shape="rect" coords="569,517,418,475"
            href="https://kuula.co/share/5NR9t?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A6" alt="A6">
          <area shape="rect" coords="568,475,426,435"
            href="https://kuula.co/share/5NRFh?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A7" alt="A7">
          <area shape="rect" coords="578,425,427,383"
            href="https://kuula.co/share/5NRFq?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A8" alt="A8">
          <area shape="rect" coords="572,339,432,378"
            href="https://kuula.co/share/5NRFc?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A9" alt="A9">
          <area shape="rect" coords="578,292,438,331"
            href="https://kuula.co/share/5NRFC?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A10"
            alt="A10">
          <area shape="rect" coords="564,254,442,291"
            href="https://kuula.co/share/5NRFW?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A11"
            alt="A11">
          <area shape="rect" coords="568,208,446,245"
            href="https://kuula.co/share/5NRJ0?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="A12"
            alt="A12">

          <area shape="rect" coords="1281,225,1318,141"
            href="https://kuula.co/share/5N3y7?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B1" alt="B1">
          <area shape="rect" coords="1209,223,1277,138"
            href="https://kuula.co/share/5N3Wr?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B2 and B3"
            alt="B2 and B3">
          <area shape="rect" coords="1137,224,1205,139"
            href="https://kuula.co/share/5N3WQ?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B4 and B5"
            alt="B4 and B5">
          <area shape="rect" coords="1070,221,1138,136"
            href="https://kuula.co/share/5N3WR?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B6 and B7"
            alt="B6 and B7">
          <area shape="rect" coords="1002,222,1070,137"
            href="https://kuula.co/share/5N3Ww?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B8 and B9"
            alt="B8 and B9">
          <area shape="rect" coords="933,218,1001,133"
            href="https://kuula.co/share/5N3yM?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B10 and B11"
            alt="B10 and B11">
          <area shape="rect" coords="865,221,933,136"
            href="https://kuula.co/share/5N3y1?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B12 and B13"
            alt="B12 and B13">
          <area shape="rect" coords="795,217,863,132"
            href="https://kuula.co/share/5N3yX?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B14 and B15"
            alt="B14 and B15">
          <area shape="rect" coords="730,221,798,136"
            href="https://kuula.co/share/5N3yY?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B16 and B17"
            alt="B16 and B17">
          <area shape="rect" coords="657,221,725,13"
            href="https://kuula.co/share/5N3y0?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B18 and B19"
            alt="B18 and B19">
          <area shape="rect" coords="648,138,605,207"
            href="https://kuula.co/share/5N3yN?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="B20"
            alt="B20">



          <area shape="rect" coords="1255,443,1352,340"
            href="https://kuula.co/share/5Nb8K?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D1 and D2"
            alt="D1 and D2">

          <area shape="rect" coords="1180,440,1244,331"
            href="https://kuula.co/share/5Nb8X?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D3 and D4"
            alt="D3 and D4">

          <area shape="rect" coords="1103,439,1163,343"
            href="https://kuula.co/share/5Nb8F?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D5 and D6"
            alt="D5 and D6">

          <area shape="rect" coords="1031,441,1091,345"
            href="https://kuula.co/share/5Nb8v?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D7 and D8"
            alt="D7 and D8">

          <area shape="rect" coords="956,433,1016,337"
            href="https://kuula.co/share/5Nb8V?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D9 and D10"
            alt="D9 and D10">


          <area shape="rect" coords="883,442,943,34"
            href="https://kuula.co/share/5Nb8x?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D11 and D12"
            alt="D11 and D12">

          <area shape="rect" coords="813,442,873,346"
            href="https://kuula.co/share/5Nb88?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D13 and D14"
            alt="D13 and D14">

          <area shape="rect" coords="770,586,804,346"
            href="https://kuula.co/share/5Nb86?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D15 and D18"
            alt="D15 and D18">

          <area shape="rect" coords="817,601,891,458"
            href="https://kuula.co/share/5Nb8g?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D19 and D20"
            alt="D19 and D20">

          <area shape="rect" coords="903,598,980,458"
            href="https://kuula.co/share/5Nb8C?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D21 and D22"
            alt="D21 and D22">


          <area shape="rect" coords="991,598,1068,458"
            href="https://kuula.co/share/5Nb8k?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D23 and D24"
            alt="D23 and D24">

          <area shape="rect" coords="1079,598,1164,458"
            href="https://kuula.co/share/5Nb89?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D25 and D26"
            alt="D25 and D26">

          <area shape="rect" coords="1174,599,1259,459"
            href="https://kuula.co/share/5Nb8J?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D27 and D28"
            alt="D27 and D28">

          <area shape="rect" coords="1269,597,1380,460"
            href="https://kuula.co/share/5Nb8c?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1" target="_blank" title="D29 and D30"
            alt="D29 and D30">

        </map>
        <!-- <map name="Yacht">
          <area shape="rect" coords="561,96,108,181" href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=ANeQ2jPRfZ"
            target="_blank" title="A1" alt="A1">
          <area shape="rect" coords="419,193,146,255"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=8Z5ZeKXehpZ" target="_blank" title="A2" alt="A2">
          <area shape="rect" coords="409,279,95,336"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=tDrVKIVKk1P" target="_blank" title="A3" alt="A3">
          <area shape="rect" coords="386,362,72,439"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=j7Jkru50gmH" target="_blank" title="A4" alt="A4">
          <area shape="rect" coords="389,455,16,525" href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=wu-OfVRaK6"
            target="_blank" title="A5" alt="A5">
          <area shape="rect" coords="569,517,418,475"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=UF8cDjBLFh" target="_blank" title="A6" alt="A6">
          <area shape="rect" coords="568,475,426,435"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=iSLYvm58Lam" target="_blank" title="A7" alt="A7">
          <area shape="rect" coords="578,425,427,383"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=XsRRdOCVyfd" target="_blank" title="A8" alt="A8">
          <area shape="rect" coords="572,339,432,378"
            href=" https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=wKp6PW1Wqxw" target="_blank" title="A9" alt="A9">
          <area shape="rect" coords="578,292,438,331"
            href=" https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=gdCQhzfaKfM" target="_blank" title="A10" alt="A10">
          <area shape="rect" coords="564,254,442,291"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=WjDMHFfrTRv" target="_blank" title="A11" alt="A11">
          <area shape="rect" coords="568,208,446,245"
            href="https://app.cloudpano.com/tours/D_0nXoSSLNt?sceneId=T2lJQheUmM" target="_blank" title="A12" alt="A12">

          <area shape="rect" coords="1281,225,1318,141" href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=jWhFQWcPY"
            target="_blank" title="B1" alt="B1">
          <area shape="rect" coords="1209,223,1277,138"
            href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=TRbApDpNYZ" target="_blank" title="B2 and B3"
            alt="B2 and B3">
          <area shape="rect" coords="1137,224,1205,139"
            href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=XK51X5ggPr" target="_blank" title="B4 and B5"
            alt="B4 and B5">
          <area shape="rect" coords="1070,221,1138,136"
            href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=sVo1lmIXJ-" target="_blank" title="B6 and B7"
            alt="B6 and B7">
          <area shape="rect" coords="1002,222,1070,137"
            href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=n3ipBGD9Tp" target="_blank" title="B8 and B9"
            alt="B8 and B9">
          <area shape="rect" coords="933,218,1001,133" href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=--uq14BHtg"
            target="_blank" title="B10 and B11" alt="B10 and B11">
          <area shape="rect" coords="865,221,933,136" href="ttps://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=drDunid8IU6"
            target="_blank" title="B12 and B13" alt="B12 and B13">
          <area shape="rect" coords="795,217,863,132" href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=D-X9Kx3xUk-"
            target="_blank" title="B14 and B15" alt="B14 and B15">
          <area shape="rect" coords="730,221,798,136" href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=5Rybmvd0d7Y"
            target="_blank" title="B16 and B17" alt="B16 and B17">
          <area shape="rect" coords="657,221,725,13" href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=1FtQroXylla"
            target="_blank" title="B18 and B19" alt="B18 and B19">
          <area shape="rect" coords="648,138,605,207" href="https://app.cloudpano.com/tours/Yjxk0QY-u?sceneId=SvXUuc68XQA"
            target="_blank" title="B20" alt="B20">



          <area shape="rect" coords="1255,443,1352,340"
            href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=4-xOVm4TpDA" target="_blank" title="D1 and D2"
            alt="D1 and D2">

          <area shape="rect" coords="1180,440,1244,331"
            href="hhttps://app.cloudpano.com/tours/spUq9yNiH?sceneId=iO8oAy_aDZ5" target="_blank" title="D3 and D4"
            alt="D3 and D4">

          <area shape="rect" coords="1103,439,1163,343"
            href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=XVHpaxt2Azq" target="_blank" title="D5 and D6"
            alt="D5 and D6">

          <area shape="rect" coords="1031,441,1091,345"
            href=" https://app.cloudpano.com/tours/spUq9yNiH?sceneId=6zGfJGzqQza" target="_blank" title="D7 and D8"
            alt="D7 and D8">

          <area shape="rect" coords="956,433,1016,337"
            href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=F6OOYfC8Zjs" target="_blank" title="D9 and D10"
            alt="D9 and D10">


          <area shape="rect" coords="883,442,943,34" href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=J1Z_49mugiA"
            target="_blank" title="D11 and D12" alt="D11 and D12">

          <area shape="rect" coords="813,442,873,346" href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=VXNoYZmv9NU"
            target="_blank" title="D13 and D14" alt="D13 and D14">

          <area shape="rect" coords="770,586,804,346" href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=NpDbfJPJVrQ"
            target="_blank" title="D15 and D18" alt="D15 and D18">

          <area shape="rect" coords="817,601,891,458" href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=2PA0nBuU3B8"
            target="_blank" title="D19 and D20" alt="D19 and D20">

          <area shape="rect" coords="903,598,980,458" href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=u5t7-F59vZb"
            target="_blank" title="D21 and D22" alt="D21 and D22">


          <area shape="rect" coords="991,598,1068,458"
            href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=zLR_ygeKcqT" target="_blank" title="D23 and D24"
            alt="D23 and D24">

          <area shape="rect" coords="1079,598,1164,458"
            href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=u42PDmz_qjG" target="_blank" title="D25 and D26"
            alt="D25 and D26">

          <area shape="rect" coords="1174,599,1259,459"
            href=" https://app.cloudpano.com/tours/spUq9yNiH?sceneId=SpGUDDnD56J" target="_blank" title="D27 and D28"
            alt="D27 and D28">

          <area shape="rect" coords="1269,597,1380,460"
            href="https://app.cloudpano.com/tours/spUq9yNiH?sceneId=o6hTVucK63N" target="_blank" title="D29 and D30"
            alt="D29 and D30">

        </map> -->
      </div>
    </div>
  </div>
  <!-- <OffCanvas /> -->
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType",
  //   props: {
  //     assetType: String
  //   },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
      assetType: 'main-beach-superyacht-marina',
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + 'main-beach-superyacht-marina' + "/"),
      assetOrder: null
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          console.log(item.ObjectName === 'floorplans')
          console.log(item.ObjectName)
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });

        // Assign type menu array
        console.log(this.assetType)
        //   console.log(this.assetType1)
        console.log('2-bed')
        console.log(this.assetType === '2-bed')
        console.log(menuItems)
        if (this.assetType === 'main-beach-superyacht-marina') {
          this.assetOrder = this.theme.ritzCarltonLobbyMenuOrder
        }

        // Sort array
        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.assetOrder
          }
        })

        console.log(customMenu)

        this.assetTypes = customMenu
        console.log(this.assetTypes)
        console.log("HI WE SORTED")
      })
      .catch(error => {
        console.log(error)
      }),

      // api call get video collection details
      BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
        .then(response => {
          console.log("collections")
          console.log(response)
          this.collection = response.data[0]
        })
        .catch(error => {
          console.log(error)
        })
  },
  setup() {
    // load global config
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
