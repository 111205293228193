<template>

  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-center v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset-rev"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="videoStatus">
    <iframe :src="`https://iframe.mediadelivery.net/embed/${videoCollectionId}/${videoGuid}?autoplay=true&loop=true`"
      loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "VideoView",
  props: [
    'guid',
    'collectionId'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
        assetTypes: null,
        assetOrder: null,
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
        videoStatus: false,
        assetNames: null,
        assetUrls: null,
        videoCollectionId: null,
        videoGuid: null,
    }
  },
  created() {
    console.log("testing")
    BunnyNetService.getVideos(this.collectionId, this.guid)
      .then(response => {
        // Remap array (inject asset names)
        console.log(response)
        let j = 0;
        const menuItems = response.data.items.map((item) => {
			if (this.assetUrls && this.assetUrls.length >= 1){
				return {
					guid: item.guid,
					videoLibraryId: item.videoLibraryId,
					thumbnailFileName: item.thumbnailFileName,
					ObjectName: item.ObjectName,
					title: this.assetNames[j],
					id: j++
				};
			} else {
				return {
					guid: item.guid,
					videoLibraryId: item.videoLibraryId,
					thumbnailFileName: item.thumbnailFileName,
					ObjectName: item.ObjectName,
					title: unslugify(item.title.split('.')[0]),
					id: j++
				};
			}
        });

        // Custom order array
        if ( this.assetOrder && this.assetOrder.length >= 1 ) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems;
        }
        console.log(this.assetTypes)
        this.videoCollectionId = this.assetTypes[0].videoLibraryId
        this.videoGuid = this.assetTypes[0].guid
        this.videoStatus = true
      })
      .catch(error => {
        console.log(error)
      })
  },
  setup () {
    // load global config
    const {theme} = global

    return {
      theme
    }
  }
}
</script>