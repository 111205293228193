<template>
  <div class="offcanvas offcanvascrew offcanvas-end" tabindex="-1" id="offcanvasCrew"
    aria-labelledby="offcanvasCrewLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasCrewLabel"></h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body" style="background-color: #F2F3F6;">
      <div class="row h-100">
        <div class="col-12 d-flex justify-content-center align-items-center flex-column crew-outer">
          <div class="row">
            <div class="col-12 text-center m-auto pb-3">
              <h2 class="elementor-heading-title elementor-size-default"
                style="color: #000120; font-family: 'Albra', Sans-serif; font-size: 30px; font-weight: normal; text-transform: uppercase; letter-spacing: 3.8px;">
                Meet the Crew</h2>
            </div>
            <div class="col-12 text-center m-auto pb-3">
              <div class="elementor-divider" style="width: 100%; display: flex;">
                <span class="elementor-divider-separator"
                  style="border-top: 2px solid #000120; width: 6%; margin: 0 auto;"></span>
              </div>
            </div>
            <div class="col-12 text-center m-auto pb-3">
              <div
                style="max-width: 560px; text-align: center;color: #000000; font-size: 16px; font-weight: 300; font-family: 'Sweet Sans Pro'; margin: auto;">
                The MBSM is a world-class collaboration of the industry’s leading, most celebrated experts, who have come
                together to create one of Australia’s most sophisticated yachting destinations with state-of-the-art
                amenity.
              </div>
            </div>
          </div>
          <div class="row crew-container">
            <div class="col-12 m-auto d-flex justify-content-evenly">
              <div class="row">
                <div class="col-12 col-sm-4 text-center mt-5">
                  <img src="/img/ui-theme/ross.png" alt="Ross Pelligra"
                    style="border-style: solid;border-width: 0px 0px 1px 0px; border-color: black;">

                  <div
                    style=" color: #222222; font-family: 'Roboto', Sans-serif; font-size: 18px; font-weight: 500; text-transform: uppercase; letter-spacing: 2px; text-align: center; margin: 20px 0px 20px 0px;">
                    Ross Pelligra
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #9D9FA4; font-family: 'gothic', Sans-serif;font-size: 16px;font-weight: 400;letter-spacing: 2px; margin: 0px 0px 20px 0px;">
                    CHAIRMAN
                    <br>
                    PELLIGRA GROUP
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #222222; font-family: 'Sweet Sans Pro', Sans-serif; font-size: 15px; font-weight: 300;">
                    A third-generation property developer and venture capitalist, Ross Pelligra is Chairman of the
                    Pelligra Group, a company he has grown with a multi-jurisdictional investment portfolio across
                    Australia and overseas. This includes various property classes and trading entities including but not
                    limited to Manufacturing, Sporting &amp; Entertainment and Hotel &amp; Tourism. Ross continues to push
                    the boundaries, grow and innovate as he passionately works towards increasing his hotel portfolio and
                    new accommodation offerings through progressive technology and cutting edge design.
                  </div>
                </div>
                <div class="col-12 col-sm-4 text-center mt-5">
                  <img src="/img/ui-theme/dean.png" alt="DEAN GIANNARELLI"
                    style="border-style: solid;border-width: 0px 0px 1px 0px; border-color: black;">
                  <div
                    style=" color: #222222; font-family: 'Roboto', Sans-serif; font-size: 18px; font-weight: 500; text-transform: uppercase; letter-spacing: 2px; text-align: center; margin: 20px 0px 20px 0px;">
                    DEAN GIANNARELLI
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #9D9FA4; font-family: 'gothic', Sans-serif;font-size: 16px;font-weight: 400;letter-spacing: 2px; margin: 0px 0px 20px 0px;">
                    DIRECTOR
                    <br>
                    GIANNARELLI GROUP
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #222222; font-family: 'Sweet Sans Pro', Sans-serif; font-size: 15px; font-weight: 300;">
                    With over 35 years experience in developing and managing businesses from inception to maturity, Dean
                    Giannarelli founded Giannarelli Group, a diversified, property development group specialising in
                    commercial, industrial, retail and residential sectors across Australia. The company has completed
                    over $400 million worth of development nation-wide.
                    <br>
                    <br>
                    Having led large- scale commercial property acquisitions and developments in Australia and the US,
                    Dean has significant experience in identifying, negotiating and integrating bolt-on acquisitions.
                  </div>
                </div>
                <div class="col-12 col-sm-4 text-center mt-5">
                  <img src="/img/ui-theme/dion.png" alt="DION GIANNARELLI"
                    style="border-style: solid;border-width: 0px 0px 1px 0px; border-color: black;">
                  <div
                    style=" color: #222222; font-family: 'Roboto', Sans-serif; font-size: 18px; font-weight: 500; text-transform: uppercase; letter-spacing: 2px; text-align: center; margin: 20px 0px 20px 0px;">
                    DION GIANNARELLI
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #9D9FA4; font-family: 'gothic', Sans-serif;font-size: 16px;font-weight: 400;letter-spacing: 2px; margin: 0px 0px 20px 0px;">
                    DIRECTOR
                    <br>
                    GIANNARELLI GROUP
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #222222; font-family: 'Sweet Sans Pro', Sans-serif; font-size: 15px; font-weight: 300;">
                    As Director of Giannarelli Group, Dion Giannarelli is engaged in high-end project management and
                    residential and commercial real estate development.
                    <br>
                    <br>
                    Over the last 15 years, he has expanded both nationally and internationally into the US property
                    market and has been extremely active in developing projects across a range of sectors from commercial
                    to residential.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-evenly">
              <div class="row">
                <div class="offset-sm-2 col-12 col-sm-4 text-center mt-5">
                  <img src="/img/ui-theme/peter.png" alt="Ross Pelligra"
                    style="border-style: solid;border-width: 0px 0px 1px 0px; border-color: black;">
                  <div
                    style=" color: #222222; font-family: 'Roboto', Sans-serif; font-size: 18px; font-weight: 500; text-transform: uppercase; letter-spacing: 2px; text-align: center; margin: 20px 0px 20px 0px;">
                    PETER KENNON
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #9D9FA4; font-family: 'gothic', Sans-serif;font-size: 16px;font-weight: 400;letter-spacing: 2px; margin: 0px 0px 20px 0px;">
                    ARCHITECHTURE
                    <br>
                    KENNON
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #222222; font-family: 'Sweet Sans Pro', Sans-serif; font-size: 15px; font-weight: 300;">
                    Kennon is an architecture and interior design practice imbued with meaning. From private homes to
                    grander civic gestures, each project is considered for what it will contribute to the human
                    experience, and how it will fulfil our need for connection to people and place. Interrogating memory,
                    identity and our sense of being, Kennon creates enduring spaces that feel familiar —where, in the
                    finest details, stories of time and place unfold. Kennon was founded and is lead by Pete Kennon with a
                    team of highly skilled architects and interior designers who contribute to the holistic design outcome
                    across Australia and Internationally.
                  </div>
                </div>
                <div class="col-12 col-sm-4 text-center mt-5">
                  <img src="/img/ui-theme/jamie.png" alt="Ross Pelligra"
                    style="border-style: solid;border-width: 0px 0px 1px 0px; border-color: black;">
                  <div
                    style=" color: #222222; font-family: 'Roboto', Sans-serif; font-size: 18px; font-weight: 500; text-transform: uppercase; letter-spacing: 2px; text-align: center; margin: 20px 0px 20px 0px;">
                    JAMIE DURIE OAM
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #9D9FA4; font-family: 'gothic', Sans-serif;font-size: 16px;font-weight: 400;letter-spacing: 2px; margin: 0px 0px 20px 0px;">
                    LANDSCAPE
                    <br>
                    ARCHITECTURE
                  </div>
                  <div class="elementor-text-editor elementor-clearfix"
                    style="text-align: center; color: #222222; font-family: 'Sweet Sans Pro', Sans-serif; font-size: 15px; font-weight: 300;">
                    International award-winning landscape designer and horticulturalist Jamie Durie has received over 38
                    international design awards in the fields of landscape architecture, design, environmental and
                    commercial development. The teams commercial work can be seen in over 34 countries today. In honour of
                    his work over the last two decades, Jamie was awarded the Medal of the Order of Australia in 2013 for
                    services to the Environment & Australian Design as well as a Centenary Medal from Australian Prime
                    Minister for his services to the environment, television, and the community. He is also an author,
                    contributor to multiple design and lifestyle publications, has hosted over 56 television design shows
                    and is a 7-time Logie Award winner
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OffCanvasCrew',
  props: [
    'heading'
  ],
  data() {
    return {

    }
  }
};
</script>

<style scoped>
.btn-theme-menu {
  width: 60%;
  background: #000;
  color: #fff;
  font-weight: 300;
  margin: auto;
}

.phone {
  color: #000;
  text-decoration: none;
}
</style>