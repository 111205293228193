<template>
  <!-- Top navigation -->
  <DetailPDFTopNav :subheading='this.assetType' />

  <!-- Brochures
  <PdfView :pdfSource="this.pdfSource" v-if="viewType == 'brochures' && this.assetTypesReady === true" /> -->
  <!-- Brochures -->
  <div id="pdf-view-inline"></div>

  <OffCanvas />
</template>

<script>
import DetailPDFTopNav from '@/components/DetailPDFTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import PdfView from "./PdfView.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailPDFTopNav,
    PdfView,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false,
      pdfAPIReady: false,
      pdfSource: '',
      adobeApiPDFReady: false,
      adobeDCView: null,
    }
  },
  created() {

    BunnyNetService.getRenders(this.folderStructure)
    .then(response => {
        console.log(response)
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          id: j++
        };
      });

      // Custom order array based on section and type
      if( this.assetOrder ) {
        this.assetTypes = this.reorder(menuItems, this.assetOrder);
      } else {
        this.assetTypes = menuItems
      }

      this.assetTypesReady = true;

      setTimeout( () => {
            if (this.adobeApiPDFReady == true){
                console.log("It is ready")
                this.initialisePdf(this.theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + menuItems[0].LinkName, this.viewType + ".pdf")
                // this.initialisePdf("https://developmentprog.com/pdf1.pdf", "YVES Brochure.pdf")
            }
        },
        "1000"
      )
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },

    // Assign PDF filename and display name
    // pdfFilename: function() {
    //   console.log(this.assetTypes[this.order])
    //   this.fileTemp = this.assetTypes[this.order].LinkName;
    //   this.file = this.fileTemp.substr(0, this.fileTemp.lastIndexOf(".")) + ".pdf";
    //   this.fileName = this.assetTypes[this.order].ObjectName;
    //   this.fileName = this.fileName.substr(0, this.fileName.lastIndexOf(".")) + ".pdf";

    //   this.pdfSource = this.theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + "/pdfs/" + this.file;
    //   console.log(this.pdfSource);
    // },
    initialisePdf: function(pdfLink, pdfName){
        this.adobeDCView.previewFile({
            content: {location: {url: pdfLink}},
            metaData: { fileName: pdfName },
        }, {embedMode: "IN_LINE", showDownloadPDF: false, showPrintPDF: false});
    },
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  },
  mounted() {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
            this.adobeApiPDFReady = true;
            console.log("Adobe created with adobe_dc_view_sdk.ready");
        });

        // Dynamically load Adobe SDK Viewer for this page
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://documentcloud.adobe.com/view-sdk/viewer.js"
        );
        plugin.async = true;
        document.head.appendChild(plugin);
    },
    watch: {
        adobeApiPDFReady(val) {
            if (val) {
                let view = new window.AdobeDC.View({
                    clientId: "d61f95f324b548daa6555fad9f3e09f3",
                    divId: "pdf-view-inline",
                });
                this.adobeDCView = Object.freeze(view);
                console.log("Adobe is mounted with Client ID");
            }
        },
    }
}
</script>